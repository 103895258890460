/* purgecss start ignore */
@tailwind base;
@tailwind components;
/* this file is for public and private/admin pages */
a, .button-link {
  @apply text-blue-500 no-underline transition-colors duration-300 ease-in-out;
}

a:hover, a:active, .button-link:hover, .button-link:active {
  @apply underline text-blue-700;
}

a:disabled, .button-link:disabled {
  @apply opacity-75;
}

a:disabled:hover, .button-link:disabled:hover {
  @apply no-underline cursor-default;
}

.button-link {
  @apply font-light;
}

b, strong {
  @apply font-bold;
}

ul, ol {
  @apply pl-10;
}

ul li, ol li {
  @apply mb-4;
}

ul {
  @apply list-disc;
}

ol {
  @apply list-decimal;
}

address {
  @apply not-italic;
}

td {
  @apply align-top;
}

.transition {
  @apply transition-all duration-300 ease-in-out;
}

html {
  @apply font-sans leading-normal text-black;
}

.alert {
  @apply flex justify-between max-w-2xl mt-0 mx-auto mb-1 p-2 bg-gray-200 border-l-10;
}

.form-wrap .alert {
  @apply w-full;
}

.alert-success {
  @apply border-green-500;
}

.alert-info {
  @apply border-blue-400;
}

.alert-warning {
  @apply border-orange-500;
}

.alert-danger {
  @apply border-red-500;
}

.content-img-center {
  @apply block mx-auto my-4;
}

.content-img-left {
  @apply float-left my-4 mr-4;
}

.content-img-right {
  @apply float-right my-4 ml-4;
}

[type=text],
[type=password],
[type=date],
[type=datetime],
[type=datetime-local],
[type=month],
[type=week],
[type=email],
[type=number],
[type=search],
[type=tel],
[type=time],
[type=url],
[type=color],
textarea {
  @apply w-full px-2 py-1 text-black border border-gray-400 rounded-sm bg-gray-200 transition-colors duration-300 ease-in-out;
}

[type=text]:focus,
[type=password]:focus,
[type=date]:focus,
[type=datetime]:focus,
[type=datetime-local]:focus,
[type=month]:focus,
[type=week]:focus,
[type=email]:focus,
[type=number]:focus,
[type=search]:focus,
[type=tel]:focus,
[type=time]:focus,
[type=url]:focus,
[type=color]:focus,
textarea:focus {
  @apply border-gray-500 bg-gray-100;
}

textarea {
  @apply align-top text-black;
}

select {
  @apply w-full p-1 text-black border border-gray-400 rounded-sm bg-gray-200 transition-colors duration-300 ease-in-out;
  height: 2.14rem;
}

select:focus {
  @apply border-gray-500 bg-gray-100;
}

[type=checkbox] {
  @apply mr-2;
}

[type=radio] {
  @apply relative mr-1;
  top: -1px;
}

[type=submit], [type=image], label, select {
  @apply cursor-pointer;
}

[type=file] {
  @apply w-full;
}

.form-wrap {
  @apply w-11/12 max-w-lg mx-auto my-4 p-4 border border-gray-400 shadow-md;
}

.field-wrap {
  @apply mb-4;
}

.field-wrap label {
  @apply block mb-1;
}

.field-wrap-checkbox {
  @apply flex flex-wrap items-center mb-4;
}

.field-wrap-checkbox label {
  @apply inline-block mr-4 mb-0;
}

.field-wrap-radio {
  @apply mb-1;
}

.field-wrap-radios, .field-wrap-checkboxes {
  @apply mb-4;
}

.field-wrap-radios label, .field-wrap-checkboxes label {
  @apply inline-block mr-4 mb-0;
}

.field-wrap-radios legend, .field-wrap-checkboxes legend {
  @apply mb-1;
}

.field-wrap-radios .field-wrap-checkbox, .field-wrap-checkboxes .field-wrap-checkbox {
  @apply mb-1;
}

.field-errors {
  @apply mb-1 pl-0 list-none;
}

.field-errors li {
  @apply mb-1 p-2 bg-gray-200 border-l-10 border-red-700;
}

.field-wrap-readonly {
  @apply relative;
}

.field-wrap-readonly:after {
  @apply absolute right-0 text-xs opacity-50;
  top: 0.5rem;
  content: "read-only";
}

.field-wrap-readonly input {
  @apply opacity-50;
}

.field-help {
  @apply w-full mt-px text-xs italic text-gray-600 leading-tight;
  padding-left: 0.1em;
}

.form-extra_actions {
  @apply flex flex-col flex-wrap items-end justify-end list-none;
}

.form-action {
  @apply text-sm ml-4;
}

h1, h2, h3, h4, h5, h6 {
  @apply font-headings leading-tight font-bold;
}

h1 {
  @apply text-3xl;
  margin: 0.67rem 0;
}

@screen lg {
  h1 {
    @apply text-5xl;
  }
}

h2 {
  @apply text-2xl;
  margin: 0.83rem 0;
}

@screen lg {
  h2 {
    @apply text-4xl;
  }
}

h3 {
  @apply text-xl mt-8 mb-2;
  margin: 1rem 0 0.5rem;
}

@screen lg {
  h3 {
    @apply text-2xl;
  }
}

h4 {
  @apply text-lg mt-8 mb-2;
}

@screen lg {
  h4 {
    @apply text-xl;
  }
}

h5 {
  @apply text-sm;
  margin: 1.67rem 0;
}

h6 {
  @apply text-xs;
  margin: 1.45rem 0;
}

h3 + p, h4 + p {
  @apply mt-0;
}

h1 a, h2 a, h3 a, h4 a {
  @apply no-underline text-inherit;
}

h1 a:hover, h1 a:active, h2 a:hover, h2 a:active, h3 a:hover, h3 a:active, h4 a:hover, h4 a:active {
  @apply underline text-inherit;
}

p {
  @apply my-4;
}

p:first-of-type {
  @apply mt-0;
}

.w-content, .w-content-narrow, .w-content-copy {
  @apply max-w-11/12 mx-auto;
}

@screen xl {
  .w-content {
    @apply max-w-6xl;
  }
}

@screen sm {
  .w-content-narrow {
    @apply max-w-xl;
  }
}

@screen lg {
  .w-content-narrow {
    @apply max-w-4xl;
  }
}

@screen xl {
  .w-content-narrow {
    max-width: 59.5rem;
  }
}

@screen sm {
  .w-content-copy {
    @apply max-w-xl;
  }
}

@screen xl {
  .w-content-copy {
    @apply max-w-xl;
  }
}

.bg-blue-abstract {
  @apply bg-blue-700;
  background-image: url("/app/themes/default/images/bg-blue.jpg");
}

.bg-slate {
  @apply bg-cover bg-no-repeat bg-gray-900;
  background-image: url("/app/themes/default/images/slate.jpg");
}

.bg-quote {
  @apply relative bg-no-repeat z-20;
  background-position: -4rem;
  background-image: url("/app/themes/default/images/quote.svg");
}

.excerpt-wrap {
  @apply text-lg;
}

@screen lg {
  .excerpt-wrap {
    @apply text-2xl;
  }
}

.excerpt-wrap ul, .excerpt-wrap ol {
  @apply p-0;
}

.button {
  @apply py-3 px-4 text-white bg-blue-800 shadow-none border border-blue-800 rounded leading-tight transition-all duration-300 ease-in-out;
}

.button:hover {
  @apply text-white bg-blue-600 border-blue-600 no-underline transition-all duration-300 ease-in-out;
}

a.button {
  @apply inline-block;
}

.header-menu-icon {
  @apply absolute text-blue-800;
  top: 1rem;
  right: 1rem;
}

@screen md {
  .header-menu-icon {
    @apply hidden;
  }
}

.nav-open ul {
  @apply block py-6 pl-0 text-center;
}

.nav-open ~ .hp-title_box {
  @apply hidden;
}

.sidenav {
  @apply w-full;
}

@screen md {
  .sidenav {
    max-width: 14em;
    margin-right: 2rem;
  }
}

.sidenav_item {
  @apply mb-4 font-bold;
}

@screen md {
  .sidenav_item {
    @apply mb-6 text-xl;
  }
}

.sidenav_item:last-of-type {
  @apply mb-0;
}

.herobar {
  @apply w-full h-120 bg-cover bg-no-repeat;
  background-position: center 7rem;
}

@screen md {
  .herobar {
    @apply h-140 bg-center;
  }
}

.herobar-author {
  background-image: url("/app/themes/default/images/herobar-author.jpg");
}

.herobar-blog {
  background-image: url("/app/themes/default/images/blog.jpg");
}

.herobar-coach {
  background-image: url("/app/themes/default/images/executive-coach.jpg");
}

.herobar-keith {
  background-image: url("/app/themes/default/images/keith-shields-04.jpg");
}

.herobar-orgdev {
  background-image: url("/app/themes/default/images/organizational-development.jpg");
}

.herobar-hp {
  @apply h-40;
  background-image: url("/app/themes/default/images/keith-shields-04.jpg");
}

@screen md {
  .herobar-hp {
    @apply h-160;
  }
}

.herobar-hp-mobile {
  @apply w-full h-120 bg-cover bg-center bg-no-repeat;
  background-position: -12rem;
  background-image: url("/app/themes/default/images/keith-shields-04.jpg");
}

@screen md {
  .herobar-hp-mobile {
    @apply hidden;
  }
}

.herobar-author {
  background-image: url("/app/themes/default/images/herobar-author.jpg");
}

.cards-wrap {
  @apply flex flex-col flex-wrap justify-center items-center max-w-11/12 mx-auto;
}

@screen md {
  .cards-wrap {
    @apply flex-row;
  }
}

.card-link {
  @apply flex flex-col justify-between w-3/4 mb-8 mx-4 text-blue-800 border border-gray-800 rounded shadow;
}

.card-link:hover {
  @apply shadow-md no-underline;
}

@screen sm {
  .card-link {
    @apply w-56;
  }
}

.card-img {
  @apply w-full h-56 rounded-t object-cover object-bottom;
}

@screen md {
  .card-img {
    @apply w-56;
  }
}

.card-heading_wrap {
  @apply flex justify-center items-center h-20 p-4;
}

.blog_list-thumbnail-wrap {
  @apply block py-4;
}

@screen md {
  .blog_list-thumbnail-wrap {
    @apply w-1/3 pr-4 pb-0;
  }
}

.blog_list-thumbnail-wrap img:first-child {
  @apply block mx-auto;
}

.blog-post-wrap img {
  @apply inline-block;
}

.page-numbers {
  @apply inline-block mx-2 mb-4 px-4 py-2 text-sm border border-gray-400;
}

.page-numbers:hover {
  @apply shadow no-underline;
}

.page-numbers.current {
  @apply bg-gray-200;
}

.page-numbers.current:hover {
  @apply shadow-none;
}

.bg-honeycomb {
  @apply bg-gray-900;
}

@screen md {
  .bg-honeycomb {
    background-image: url("/app/themes/default/images/bg-honeycomb.jpg");
  }
}

.honeycomb-content {
  @apply order-2 max-w-11/12 mx-auto mb-4 p-6 bg-white bg-opacity-75;
}

@screen sm {
  .honeycomb-content {
    @apply w-4/5;
  }
}

@screen md {
  .honeycomb-content {
    @apply order-1 w-3/5 mb-0;
  }
}

@screen lg {
  .honeycomb-content {
    @apply w-144;
  }
}

.honeycomb-content a {
  @apply text-blue-700;
}

.honeycomb-content h2 a {
  @apply text-gray-700;
}

.honeycomb-img_wrap {
  @apply order-1 w-4/5 mx-auto mb-6;
}

@screen sm {
  .honeycomb-img_wrap {
    @apply w-4/5;
  }
}

@screen md {
  .honeycomb-img_wrap {
    @apply order-2 w-2/5 mx-0 mb-0 pl-8;
  }
}

@screen lg {
  .honeycomb-img_wrap {
    @apply w-128;
  }
}

.honeycomb-img {
  @apply block max-h-80 w-auto mx-auto object-contain;
}

@screen sm {
  .honeycomb-img {
    @apply max-w-80;
  }
}

@screen md {
  .honeycomb-img {
    @apply max-w-full max-h-full mx-0;
  }
}

.honeycomb-button {
  @apply transform mt-6 opacity-75 transition-all duration-300 ease-in-out;
}

.honeycomb-button:hover {
  @apply -translate-y-1 opacity-100;
}

.review-quote-icon {
  @apply -mt-2 mb-8 -ml-4 pt-8 pl-6 bg-no-repeat;
  background-image: url("/app/themes/default/images/quote.svg");
  background-position: left 0.75em top 1em;
}

@screen md {
  .review-quote-icon {
    @apply -ml-12 pl-12;
    background-position: left 0em top 1em;
  }
}

.review-quote-caption {
  @apply max-w-sm mt-2 ml-auto text-sm text-right italic text-gray-600 font-thin;
}

@screen md {
  .review-quote-caption {
    @apply text-base;
  }
}

.embed-wrap {
  @apply w-full max-w-5xl mx-auto;
}

.embed-container {
  @apply relative h-auto bg-black overflow-hidden;
}

.embed-container-21-9 {
  padding-top: 42.85%;
}

.embed-container-18-9 {
  padding-top: 50%;
}

.embed-container-16-9 {
  padding-top: 56.25%;
}

.embed-container-4-3 {
  padding-top: 75%;
}

.embed-container iframe,
.embed-container object,
.embed-container embed {
  @apply absolute top-0 left-0 right-0 w-full h-full max-w-full mx-auto;
}

.mce-content-body {
  @apply px-4;
}

.wp-block-embed {
  @apply max-w-3xl mx-auto mb-4;
}

.hp-title_box {
  @apply flex justify-center items-center w-full mx-auto text-center;
}

@screen md {
  .hp-title_box {
    @apply w-1/2 h-160 mr-0 text-left;
  }
}

/* purgecss end ignore */
@tailwind utilities;
